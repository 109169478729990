import { createGlobalState } from 'react-hooks-global-state';

import cookieConsent from 'Utils/cookieConsent';

const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState({
  consent: cookieConsent.read(),
});

cookieConsent.listen(consent => setGlobalState('consent', consent));

export const save = newConsent => {
  const consent = {
    ...getGlobalState('consent'),
    ...newConsent,
  };

  cookieConsent.save(consent);
  setGlobalState('consent', consent);
};

const useConsent = () => useGlobalState('consent');

export default useConsent;
