/**
 * PageWrapper component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import { node } from 'prop-types';
import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import CookiesBanner from 'Components/CookiesBanner/CookiesBanner';
import Footer from 'Components/Footer';
import GTag from 'Components/GTag/GTag';
import Header from 'Components/Header';

// Include general styles to be applied throughout the website.
import 'Styles/main.scss';

const PageWrapper = ({ children }) => {
  const { navbarData, settingsData } = useStaticQuery(graphql`
    {
      navbarData: allMarkdownRemark(filter: {
        frontmatter: {
          templateKey: { eq: "navbar" }
        }
      }) {
        edges {
          node {
            id
            frontmatter {
              menuItems {
                label
                linkType
                linkURL
                submenu {
                  label
                  linkType
                  linkURL
                }
              }
            }
          }
        }
      }
      settingsData: allMarkdownRemark(filter: {
        frontmatter: {
          templateKey: { eq: "settings" }
        }
      }) {
        edges {
          node {
            id
            frontmatter {
              copyright
              siteDescription
              siteTitle
              logos {
                imageAlt
                label
                linkURL
                image {
                  # @todo pick correct image sizes for logos
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // @todo maybe add in some error handling in case no navbar file can be found
  const navbar = navbarData.edges[0].node.frontmatter;
  const settings = settingsData.edges[0].node.frontmatter;

  const [ onScroll, setOnScroll ] = useState(false);

  useEffect(() => {
    const scroll = () => {
      if (window.pageYOffset > 40) {
        setOnScroll(true);
      }
      else {
        setOnScroll(false);
      }
    };
    window.addEventListener('scroll', scroll);
    return () => window.removeEventListener('scroll', scroll);
  }, []);

  return (

    <div
      className={ clsx('page__wrapper', {
        'is-thinner': onScroll,
      }) }
    >
      <GTag />
      <CookiesBanner />
      <Header
        navbar={ navbar.menuItems }
        siteTitle={ settings.siteTitle }
      />
      <div className="page__wrapper__content">{ children }</div>
      <Footer
        copyright={ settings.copyright }
        logos={ settings.logos }
        siteTitle={ settings.siteTitle }
      />
    </div>
  );
};

PageWrapper.propTypes = {
  children: node.isRequired,
};

export default PageWrapper;
