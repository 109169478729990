import EventEmitter from 'events';

import cookies from 'Utils/cookies';

export const cookieVersion = 'v1';

export const eeConsent = new EventEmitter();

function getCookieName(name) {
  // eslint-disable-next-line no-nested-ternary
  const version = name
    ? name.match(/^v[0-9\-.]+$/)
      ? name
      : `v${name}`
    : '';

  return version ? `CookieConsent-${version}` : '';
}

const cookieName = getCookieName(cookieVersion);

const cookieConsent = {
  listen: handler => eeConsent.on('consentUpdated', handler),
  unlisten: handler => eeConsent.off('consentUpdated', handler),

  read: consentType => {
    if (typeof window === 'undefined') {
      return null;
    }

    try {
      const saved = cookies.get(cookieName);

      if (consentType) {
        return saved?.[consentType] || false;
      }

      return saved ?? (cookieName ? {} : null);
    }
    catch (ex) {
      return null;
    }
  },

  save: value => {
    cookies.set(cookieName, value);
    eeConsent.emit('consentUpdated', value);
  },
};

export default cookieConsent;
