import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createGlobalState } from 'react-hooks-global-state';

import FormCheckbox from 'Components/Form/Checkbox';
import useConsent, { save } from 'Hooks/useConsent';
import useRendered from 'Hooks/useRendered';
import { cookieVersion } from 'Utils/cookieConsent';

const cookiesBanner = {
  body: 'With your permission, by navigating this site data will be stored in your browser, in order to enhance your browsing experience.',
  options: [
    [ 'essential', 'Essential - Remember your actions, for a smooth experience across sessions.', true ],
    [ 'analytics', 'Analytics - Site behavior and interaction, to improve and evolve the platform.' ],
    // [ 'ads', 'Advertising - Measurement and personalization of advertising signals.' ],
  ],
  title: 'Your privacy is important to us.',
};

const mandatoryKey = cookiesBanner.options.filter(([ , , mandatory ]) => mandatory)
  .map(([ option ]) => option)[0];

const { setGlobalState, useGlobalState } = createGlobalState({ screen: 'banner' });

const setScreen = screen => setGlobalState('screen', screen);

const CookiesBanner = () => {
  const [ consent ] = useConsent();
  const rendered = useRendered();
  const [ options, setOptions ] = useState(
    cookiesBanner.options.reduce(
      (acc, [ option, , mandatory ]) => ({
        ...acc,
        [option]: consent?.[option] || mandatory || false,
      }),
      {},
    ),
  );

  const [ screen ] = useGlobalState('screen');

  useEffect(() => {
    if (!screen && !consent?.[mandatoryKey]) {
      setScreen('banner');
    }
  }, [ consent, screen ]);

  if (!rendered || !cookieVersion) {
    return null;
  }

  return (
    (!consent?.[mandatoryKey] || screen === 'options') && (
      <section
        className={ clsx('cookies-banner', {
          'cookies-banner--show': screen,
        }) }
      >
        <div className="cookies-banner__page-container">
          <div className="cookies-banner__content">
            <h5>{ cookiesBanner.title }</h5>
            <p>{ cookiesBanner.body }</p>

            { screen === 'options' && (
              <div className="cookies-banner__options">
                { cookiesBanner.options.map(([ option, label, mandatory ]) => (
                  <FormCheckbox
                    key={ option }
                    checked={ options[option] }
                    disabled={ mandatory }
                    label={ label }
                    name={ `switch-${option}` }
                    onChange={ e => setOptions({
                      ...options,
                      [option]: e.target.checked,
                    }) }
                  />
                )) }
              </div>
            ) }
          </div>

          <div className="cookies-banner__btn-wrapper">
            { screen === 'banner' && (
              <button
                className="cookies-banner__btn cookies-banner__btn--more"
                onClick={ () => setScreen('options') }
                type="button"
              >
                More Options
              </button>
            ) }

            { screen === 'banner' && (
              <button
                className="cookies-banner__btn"
                onClick={ () => {
                  save(
                    cookiesBanner.options.reduce(
                      (acc, [ option ]) => ({
                        ...acc,
                        [option]: true,
                      }),
                      {},
                    ),
                  );
                  setScreen('');
                } }
                type="button"
              >
                Accept All
              </button>
            ) }

            { screen === 'options' && (
              <button
                className="cookies-banner__btn"
                onClick={ () => {
                  save(options);
                  setScreen('');
                } }
                type="button"
              >
                Save Preferences
              </button>
            ) }
          </div>
        </div>
      </section>
    )
  );
};

export default CookiesBanner;
